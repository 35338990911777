import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Form, Button, Input } from 'antd';
import { fullNameValidator } from '@helpers/validators';

import { useAuth } from '@hooks/useAuth';
import { useAssetReview } from '@components/AssetReviewProvider';

const { Title, Text } = Typography;

function EnterEmail() {
  const { loginForAW, signUpForAW, error } = useAuth();
  const navigate = useNavigate();
  const { reviewId, password } = useAssetReview();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [isShowFullName, setIsShowFullName] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
  };

  useEffect(() => {
    if (error && error.error === 'signup_required') {
      setIsShowFullName(true);
      setIsSubmit(false);
    }
  }, [error]);

  const onSubmit = async ({
    email,
    fullName
  }: {
    email: string;
    fullName?: string;
  }): Promise<void> => {
    const hasFullName = isShowFullName && fullName;
    if (hasFullName && !fullNameValidator(fullName)) {
      form.setFields([
        {
          name: 'fullName',
          errors: ['Please enter valid First and Last Name']
        }
      ]);
      return;
    }
    setIsSubmit(true);
    try {
      if (hasFullName) {
        const [firstName, ...lastName] = hasFullName.split(' ').filter(Boolean);

        await signUpForAW({
          email,
          firstName,
          lastName: lastName.join(' '),
          assetVersionId: reviewId as string
        });
        return;
      }
      await loginForAW({ email, assetVersionId: reviewId as string });
    } catch (error: any) {
      setIsSubmit(false);
      setIsShowFullName(true);
      if (isShowFullName) {
        form.setFields([
          {
            name: 'fullName',
            errors: ['Something went wrong']
          }
        ]);
      }
    }
  };

  return (
    <div className="external_reviewer_enter-email">
      <Title level={2} className="external_reviewer_title">
        Enter your email
      </Title>
      <Text className="external_reviewer_text">
        Enter your email to view the creative media shared with you
      </Text>
      <Form
        form={form}
        onFinish={onSubmit}
        layout="vertical"
        className="external_reviewer_form"
      >
        <Form.Item
          name="email"
          className="external_reviewer_form-row"
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Email required.'
            }
          ]}
        >
          <Input placeholder="Email" size="large" onChange={onChangeEmail} />
        </Form.Item>
        {isShowFullName && (
          <Form.Item
            name="fullName"
            className="external_reviewer_form-row"
            rules={[{ required: true, message: 'Enter full name required.' }]}
          >
            <Input placeholder="Enter full name" size="large" />
          </Form.Item>
        )}
        <Form.Item className="external_reviewer_form-bottom">
          <Button
            type="primary"
            htmlType="submit"
            className="submit_form external_reviewer_form-btn"
            size="large"
            disabled={!email}
            loading={isSubmit}
          >
            {isShowFullName ? 'View' : 'Next'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default EnterEmail;
